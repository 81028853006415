import { Language, Email, Apple, Android } from '@mui/icons-material'
import {
    Grid,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { NavLink as RouterLink } from 'react-router-dom'
// @ts-expect-error
import details from 'details.json'
// @ts-expect-error
import preferences from 'preferences.json'
import { getBrandFullName } from 'src/utils/formatters'
import { CLICK_UP_LINKS, MOBILE_APP_LINKS } from 'src/constants'
import { useOrganisation } from 'src/hooks/useOrganisation'
import { ORGANISATION } from 'src/constants/general'

interface Props {
    setManualStep: React.Dispatch<React.SetStateAction<number | undefined>>
}

const PageOne = ({ setManualStep }: Props) => {
    /* Hooks & Use Effects */
    const theme = useTheme()
    const environment = useOrganisation()

    /* States */

    /* API Calls */

    /* Functions & Functional Components */

    return (
        <>
            <Grid
                container
                sx={{
                    justifyContent: 'flex-start',
                }}
            >
                <Grid item container flexDirection={'column'}>
                    <Typography variant="h2" component="span">
                        <Typography variant="h2" component="span">
                            Welcome to{' '}
                        </Typography>
                        <Typography
                            variant="h2"
                            component="span"
                            style={{ color: theme.colors.primary.main }}
                        >
                            {getBrandFullName()}.
                        </Typography>
                    </Typography>
                    <Typography variant="h5" component="span" sx={{ mt: 1 }}>
                        Your simplified smart metering platform.
                        {environment !== ORGANISATION.SWITCH && ' Powered by '}
                        {environment !== ORGANISATION.SWITCH && (
                            <Typography
                                variant="h5"
                                component="a"
                                href="https://www.switch.org.za"
                                style={{
                                    color: theme.colors.primary.main,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                            >
                                Switch Energy.
                            </Typography>
                        )}
                    </Typography>
                </Grid>
                <Grid item container flexDirection={'column'} sx={{ mt: 5 }}>
                    <Typography variant="h4" component="span">
                        Quick Links
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={details.email}
                                component={Link}
                                sx={{ px: 0 }}
                            >
                                <ListItemIcon>
                                    <Language sx={{ color: 'black' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`${getBrandFullName()} website`}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={RouterLink}
                                to={`mailto:${details.email}`}
                                sx={{ px: 0 }}
                            >
                                <ListItemIcon>
                                    <Email sx={{ color: 'black' }} />
                                </ListItemIcon>
                                <ListItemText primary={details.email} />
                            </ListItemButton>
                        </ListItem>
                        {preferences.mobile_features !== false && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    href={MOBILE_APP_LINKS.IOS}
                                    component={Link}
                                    sx={{ px: 0 }}
                                >
                                    <ListItemIcon>
                                        <Apple sx={{ color: 'black' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Switch Energy IOS App" />
                                </ListItemButton>
                            </ListItem>
                        )}

                        {preferences.mobile_features !== false && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    href={MOBILE_APP_LINKS.ANDROID}
                                    component={Link}
                                    sx={{ px: 0 }}
                                >
                                    <ListItemIcon>
                                        <Android sx={{ color: 'black' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Switch Energy Android App" />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Grid>

                <Grid item container flexDirection={'column'} sx={{ mt: 5 }}>
                    <Typography variant="h4" component="span">
                        Help Guides
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={CLICK_UP_LINKS.USERS}
                                component={Link}
                                sx={{ px: 0 }}
                            >
                                <ListItemText
                                    primary={`\u2022 User Management`}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={CLICK_UP_LINKS.ACCOUNTS}
                                component={Link}
                                sx={{ px: 0 }}
                            >
                                <ListItemText
                                    primary={`\u2022 Account Management`}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={CLICK_UP_LINKS.TARIFFS}
                                component={Link}
                                sx={{ px: 0 }}
                            >
                                <ListItemText
                                    primary={`\u2022 Tariff Management`}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                href={CLICK_UP_LINKS.BILLING_RUNS}
                                component={Link}
                                sx={{ px: 0 }}
                            >
                                <ListItemText
                                    primary={`\u2022 Billing Run Management`}
                                />
                            </ListItemButton>
                        </ListItem>
                        {environment === ORGANISATION.EXPROP && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    href={CLICK_UP_LINKS.PROFIT_SHARE}
                                    component={Link}
                                    sx={{ px: 0 }}
                                >
                                    <ListItemText
                                        primary={`\u2022 Profit Share Guide`}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>
        </>
    )
}

export default PageOne
