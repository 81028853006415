import { QuestionMark } from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Bolt from "@mui/icons-material/Bolt";
import CloudIcon from "@mui/icons-material/Cloud";
import OpacityIcon from "@mui/icons-material/Opacity";
import { Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { blue, green, grey, purple, yellow } from "@mui/material/colors";
import React from "react";
import { UtilityType } from "switch-shared/modules/crud/utility/utility.enums";

interface UtilityChipProps {
  type?: UtilityType;
  consumption?: number;
  size?: "small" | "medium" | "large";
}

export const UtilityTypeBadge: React.FC<UtilityChipProps> = ({
  type,
  size,
}) => {
  switch (type) {
    case UtilityType.ELECTRICITY:
      return (
        <Tooltip title="Electricity">
          <Avatar
            sx={{
              bgcolor: yellow[100],
              width: size === "large" ? 40 : size === "medium" ? 33 : 27,
              height: size === "large" ? 40 : size === "medium" ? 33 : 27,
              ml: size === "small" ? 0 : 0.5,
              mb: size === "small" ? 0 : 1,
            }}
          >
            <Bolt style={{ color: yellow[800] }} fontSize={size ?? "small"} />
          </Avatar>
        </Tooltip>
      );
    case UtilityType.FIXED_FEE:
      return (
        <Tooltip title="Fixed fee">
          <Avatar
            sx={{
              bgcolor: green[100],
              width: size === "large" ? 40 : size === "medium" ? 33 : 27,
              height: size === "large" ? 40 : size === "medium" ? 33 : 27,
              ml: size === "small" ? 0 : 0.5,
              mb: size === "small" ? 0 : 1,
            }}
          >
            <AttachMoneyIcon
              style={{ color: green[800] }}
              fontSize={size ?? "small"}
            />
          </Avatar>
        </Tooltip>
      );
    case UtilityType.GAS:
      return (
        <Tooltip title="Gas">
          <Avatar
            sx={{
              bgcolor: purple[100],
              width: size === "large" ? 40 : size === "medium" ? 33 : 27,
              height: size === "large" ? 40 : size === "medium" ? 33 : 27,
              ml: size === "small" ? 0 : 0.5,
              mb: size === "small" ? 0 : 1,
            }}
          >
            <CloudIcon
              style={{ color: purple[800] }}
              fontSize={size ?? "small"}
            />
          </Avatar>
        </Tooltip>
      );
    case UtilityType.WATER:
      return (
        <Tooltip title="Water">
          <Avatar
            sx={{
              bgcolor: blue[50],
              width: size === "large" ? 40 : size === "medium" ? 33 : 27,
              height: size === "large" ? 40 : size === "medium" ? 33 : 27,
              ml: size === "small" ? 0 : 0.5,
              mb: size === "small" ? 0 : 1,
            }}
          >
            <OpacityIcon
              style={{ color: blue[500] }}
              fontSize={size ?? "small"}
            />
          </Avatar>
        </Tooltip>
      );
    default:
      return (
        <Avatar
          sx={{
            bgcolor: grey[50],
            width: size === "large" ? 40 : size === "medium" ? 33 : 27,
            height: size === "large" ? 40 : size === "medium" ? 33 : 27,
            ml: size === "small" ? 0 : 0.5,
            mb: size === "small" ? 0 : 1,
          }}
        >
          <QuestionMark
            style={{ color: grey[500] }}
            fontSize={size ?? "small"}
          />
        </Avatar>
      );
  }
};
