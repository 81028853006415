import { BucketSize } from "../enums/utility-data";
import dayjs, { Dayjs } from "dayjs";

export const capitalize = (value: string) => {
  return value.slice(0, 1).toUpperCase().concat(value.slice(1));
};

export const formatNumber = (value: number, decimals?: number) => {
  return Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: decimals ?? 3,
    minimumFractionDigits: 0,
  }).format(value);
};

export const roundUsage = (num: number, decimals: number) => {
  const factorOfTen = Math.pow(10, decimals);
  return Math.round(num * factorOfTen) / factorOfTen;
};

export const formatDateByBucket = (
  date: Date,
  bucketSize?: BucketSize,
  full?: boolean
) => {
  const day = dayjs(date);
  switch (bucketSize) {
    case BucketSize.DAILY:
      return day.format(full ? "D MMMM YYYY" : "DD/MM");
    case BucketSize.HOURLY:
      return day.format(full ? "DD MMM YYYY, HH:mm" : "HH:mm");
    case BucketSize.QUARTER_HOURLY:
      return day.format(full ? "DD MMM YYYY, HH:mm" : "HH:mm");
    case BucketSize.MINUTELY:
      return day.format(full ? "DD MMM YYYY, HH:mm" : "HH:mm");
    default:
      return day.format("D MMM YYYY, HH:mm:ss");
  }
};

export const getDateSliderTitle = (
  dateRange: "day" | "month" | "custom",
  startDate?: Dayjs,
  endDate?: Dayjs
) => {
  switch (dateRange) {
    case "day":
      return dayjs(startDate).format("D MMM YYYY");
    case "month":
      return dayjs(startDate).format("MMMM YYYY");
    default:
      return `${dayjs(startDate).format("DD MMM YYYY")} - ${dayjs(
        endDate
      ).format("DD MMM YYYY")}`;
  }
};

export const isNumber = (input: string): boolean => {
  const regex = /^[0-9.]*$/;
  if (regex.test(input) && (input.match(/\./g) || []).length < 2) {
    return true;
  }
  return false;
};

export const graphFileName = (
  title: string,
  startTime: Date | string,
  endTime: Date | string
) => {
  return `${title ?? ""} ${dayjs(startTime).format("DD-MM-YYYY HH:mm:ss")} - ${dayjs(endTime).format("DD-MM-YYYY HH:mm:ss")}`;
};
