export enum CLICK_UP_LINKS {
    USERS = 'https://doc.clickup.com/9005149805/d/h/8cbzbkd-4512/7e9415f2086ead8',
    TARIFFS = 'https://doc.clickup.com/9005149805/d/h/8cbzbkd-4632/ca9a9ad4e7bce18',
    BILLING_RUNS = 'https://doc.clickup.com/9005149805/d/h/8cbzbkd-4152/d34f3bfbc7871c3',
    ACCOUNTS = 'https://doc.clickup.com/9005149805/d/h/8cbzbkd-5472/c4f99b61271bb5b',
    METERS = 'https://doc.clickup.com/9005149805/d/h/8cbzbkd-4892/a24a03eb491ab18',
    ZONES = 'https://doc.clickup.com/9005149805/d/h/8cbzbkd-5292/9e01a9e853e6991',
    PROFIT_SHARE = 'https://doc.clickup.com/9005149805/d/h/8cbzbkd-4952/c0295547dbbc08d',
}

export enum MOBILE_APP_LINKS {
    ANDROID = 'https://play.google.com/store/apps/details?id=za.org.switchenergy.mobile',
    IOS = 'https://apps.apple.com/us/app/switch-energy/id1629686733',
}
